var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['products-table', _vm.hideAdditionalColumns ? 'products-table--simple' : '']
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedRowsLength > 0,
      expression: "selectedRowsLength > 0"
    }],
    staticClass: "bulk-options",
    style: "margin-top: ".concat(_vm.bulkOptionsMarginTop, "px;")
  }, [_c('sygni-loader', {
    attrs: {
      "is-loading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "bulk-options__row"
  }, [_c('span', {
    staticClass: "bulk-options__label"
  }, [_vm._v("Selected: " + _vm._s(_vm.selectedRowsLength))]), _c('div', {
    staticClass: "bulk-options__actions"
  }, [_c('sygni-rect-button', {
    staticClass: "bulk-options__action outline",
    on: {
      "click": function click($event) {
        return _vm.resetCheckboxes(false);
      }
    }
  }, [_vm._v("Unselect all")]), _c('div', {
    staticClass: "dropdown"
  }, [_c('div', {
    staticClass: "dropdown__placeholder"
  }, [_c('p', {
    staticClass: "dropdown__placeholder-text"
  }, [_vm._v("Actions")]), _c('div', {
    staticClass: "dropdown__placeholder-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('ARROW'),
      "alt": "Dropdown"
    }
  })])]), _c('div', {
    staticClass: "dropdown__inner"
  }, [_vm.hasOnlyBookableTransactions ? _c('div', {
    staticClass: "dropdown__option",
    on: {
      "click": function click($event) {
        return _vm.synchronise();
      }
    }
  }, [_c('p', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("Book transactions")]), _c('div', {
    staticClass: "dropdown__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('UPLOAD'),
      "alt": "Book transactions"
    }
  })])]) : _vm._e(), !_vm.hasOnlyBookableTransactions ? _c('div', {
    staticClass: "dropdown__option"
  }, [_c('p', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("No actions available")])]) : _vm._e()])])], 1)])], 1), _c('div', {
    staticClass: "products-table__inner"
  }, [_c('div', {
    staticClass: "products-table__inner-table"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "statementItemsTable",
    staticClass: "sygni-b-inner-table sygni-b-table",
    attrs: {
      "tbody-tr-class": _vm.rowClass,
      "innertable": "true",
      "fields": _vm.tableFields
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "head(selected)",
      fn: function fn() {
        return [_c('sygni-checkbox', {
          staticClass: "secondary",
          model: {
            value: _vm.selectAllRowsBoolean,
            callback: function callback($$v) {
              _vm.selectAllRowsBoolean = $$v;
            },
            expression: "selectAllRowsBoolean"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(selected)",
      fn: function fn(rowData) {
        var _vm$bulkRows$rowData$, _vm$bulkRows$rowData$2;

        return [_c('div', {
          staticClass: "table-checkbox",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.toggleTableRow(_vm.bulkRows[rowData.index], rowData.index);
            }
          }
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          attrs: {
            "disabled": _vm.isDisabled((_vm$bulkRows$rowData$ = _vm.bulkRows[rowData === null || rowData === void 0 ? void 0 : rowData.index]) === null || _vm$bulkRows$rowData$ === void 0 ? void 0 : _vm$bulkRows$rowData$.label),
            "value": (_vm$bulkRows$rowData$2 = _vm.bulkRows[rowData.index]) === null || _vm$bulkRows$rowData$2 === void 0 ? void 0 : _vm$bulkRows$rowData$2.value
          }
        })], 1)];
      }
    }, {
      key: "cell(fullNumber)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "test"
        }, [_vm._v(" " + _vm._s(rowData.item.fullNumber) + " ")])];
      }
    }, {
      key: "cell(issueDate)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm._f("dateWithoutTime")(rowData.item.issueDate)) + " ")];
      }
    }, {
      key: "cell(entityCode)",
      fn: function fn(rowData) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: _vm.getTooltipMessage(rowData.item.entityCode, 15),
            expression: "getTooltipMessage(rowData.item.entityCode, 15)",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("trimString")(rowData.item.entityCode, 15)) + " ")])];
      }
    }, {
      key: "cell(name)",
      fn: function fn(rowData) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: _vm.getTooltipMessage(rowData.item.name, 15),
            expression: "getTooltipMessage(rowData.item.name, 15)",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("trimString")(rowData.item.name, 15)) + " ")])];
      }
    }, {
      key: "cell(entityAccountNumber)",
      fn: function fn(rowData) {
        var _rowData$item;

        return [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: _vm.getTooltipMessage(_vm.$options.filters.bankAccountNumber(rowData === null || rowData === void 0 ? void 0 : (_rowData$item = rowData.item) === null || _rowData$item === void 0 ? void 0 : _rowData$item.entityAccountNumber), 10),
            expression: "getTooltipMessage($options.filters.bankAccountNumber(rowData?.item?.entityAccountNumber), 10)",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("trimString")(_vm._f("bankAccountNumber")(rowData.item.entityAccountNumber), 10)) + " ")])];
      }
    }, {
      key: "cell(description)",
      fn: function fn(rowData) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: _vm.getTooltipMessage(rowData.item.description, 30),
            expression: "getTooltipMessage(rowData.item.description, 30)",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("trimString")(rowData.item.description, 30)) + " ")])];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.amount, 2)) + " ")])];
      }
    }, {
      key: "cell(receivables)",
      fn: function fn(rowData) {
        var _rowData$item2;

        return [rowData.item.direction === 1 ? _c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item2 = rowData.item) === null || _rowData$item2 === void 0 ? void 0 : _rowData$item2.amount, 2)) + " ")]) : _c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(0, 2)) + " ")])];
      }
    }, {
      key: "cell(payables)",
      fn: function fn(rowData) {
        var _rowData$item3;

        return [rowData.item.direction === -1 ? _c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item3 = rowData.item) === null || _rowData$item3 === void 0 ? void 0 : _rowData$item3.amount, 2)) + " ")]) : _c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(0, 2)) + " ")])];
      }
    }, {
      key: "cell(currency)",
      fn: function fn(rowData) {
        var _rowData$item4;

        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.getCurrencyLabel((_rowData$item4 = rowData.item) === null || _rowData$item4 === void 0 ? void 0 : _rowData$item4.currency)) + " ")])];
      }
    }, {
      key: "cell(amountSystem)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.amountSystem, 2)) + " ")])];
      }
    }, {
      key: "cell(receivablesSystem)",
      fn: function fn(rowData) {
        var _rowData$item5;

        return [rowData.item.direction === 1 ? _c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item5 = rowData.item) === null || _rowData$item5 === void 0 ? void 0 : _rowData$item5.amountSystem, 2)) + " ")]) : _c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(0, 2)) + " ")])];
      }
    }, {
      key: "cell(payablesSystem)",
      fn: function fn(rowData) {
        var _rowData$item6;

        return [rowData.item.direction === -1 ? _c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item6 = rowData.item) === null || _rowData$item6 === void 0 ? void 0 : _rowData$item6.amountSystem, 2)) + " ")]) : _c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(0, 2)) + " ")])];
      }
    }, {
      key: "cell(ownerName)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "owner-name text-left"
        }, [_vm._v(" " + _vm._s(rowData.item.ownerName) + " ")])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(rowData) {
        return [rowData.item.type.toUpperCase() === 'LLC_SHARES' ? [_vm._v(" LLC Shares ")] : _vm._e(), rowData.item.type.toUpperCase() === 'JOINT_STOCK_SHARES' ? [_vm._v(" Joint-stock Shares ")] : _vm._e(), rowData.item.type.toUpperCase() !== 'LLC_SHARES' && rowData.item.type.toUpperCase() !== 'JOINT_STOCK_SHARES' ? [_vm._v(" " + _vm._s(_vm._f("snakeCaseToTitleCase")(rowData.item.type)) + " ")] : _vm._e()];
      }
    }, {
      key: "cell(status)",
      fn: function fn(rowData) {
        var _rowData$item7, _rowData$item8, _rowData$item9, _rowData$item10, _rowData$item11;

        return [_c('div', {
          staticClass: "status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: rowData !== null && rowData !== void 0 && (_rowData$item7 = rowData.item) !== null && _rowData$item7 !== void 0 && _rowData$item7.errorMessage ? rowData === null || rowData === void 0 ? void 0 : (_rowData$item8 = rowData.item) === null || _rowData$item8 === void 0 ? void 0 : _rowData$item8.errorMessage : '',
            expression: "rowData?.item?.errorMessage ? rowData?.item?.errorMessage : ''",
            modifiers: {
              "hover": true
            }
          }],
          class: "outline round ".concat(_vm.statusClass((_rowData$item9 = rowData.item) === null || _rowData$item9 === void 0 ? void 0 : _rowData$item9.status)),
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(" " + _vm._s(_vm.statusText((_rowData$item10 = rowData.item) === null || _rowData$item10 === void 0 ? void 0 : _rowData$item10.status)) + " "), rowData !== null && rowData !== void 0 && (_rowData$item11 = rowData.item) !== null && _rowData$item11 !== void 0 && _rowData$item11.errorMessage ? _c('div', {
          staticClass: "warning-info"
        }, [_vm._v("i")]) : _vm._e()])], 1)];
      }
    }, {
      key: "cell(value)",
      fn: function fn(rowData) {
        return [rowData.item.value.value ? _c('span', [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.value.value, 2)) + " " + _vm._s(rowData.item.value.currency) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        var _rowData$item12, _rowData$item13, _rowData$item13$statu, _rowData$item14, _rowData$item14$statu;

        return [_c('div', {
          staticClass: "actions"
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: {
              interactive: false,
              boundary: 'viewport'
            },
            expression: "{ interactive: false, boundary: 'viewport' }",
            modifiers: {
              "hover": true
            }
          }],
          class: ['action', !(rowData !== null && rowData !== void 0 && (_rowData$item12 = rowData.item) !== null && _rowData$item12 !== void 0 && _rowData$item12.investmentClientId) || (rowData === null || rowData === void 0 ? void 0 : (_rowData$item13 = rowData.item) === null || _rowData$item13 === void 0 ? void 0 : (_rowData$item13$statu = _rowData$item13.status) === null || _rowData$item13$statu === void 0 ? void 0 : _rowData$item13$statu.toUpperCase()) === 'BOOK' || (rowData === null || rowData === void 0 ? void 0 : (_rowData$item14 = rowData.item) === null || _rowData$item14 === void 0 ? void 0 : (_rowData$item14$statu = _rowData$item14.status) === null || _rowData$item14$statu === void 0 ? void 0 : _rowData$item14$statu.toUpperCase()) === 'BOOKED' ? 'disabled' : ''],
          attrs: {
            "title": "Book transaction"
          },
          on: {
            "click": function click($event) {
              return _vm.synchronise(rowData === null || rowData === void 0 ? void 0 : rowData.item);
            }
          }
        }, [_c('div', {
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('UPLOAD'),
            "alt": "Book"
          }
        })])])])];
      }
    }, {
      key: "custom-foot",
      fn: function fn(data) {
        return [_c('tr', {
          staticClass: "custom-tfoot"
        }, _vm._l(data.columns, function (i) {
          var _data$fields, _data$fields2, _data$fields3, _data$fields4, _data$fields5, _data$fields6, _data$fields7, _data$fields8, _data$fields9, _data$fields10, _data$fields11;

          return _c('td', {
            key: i,
            class: "td-".concat(data.fields[i - 1].key, " ").concat(((_data$fields = data.fields[i - 1]) === null || _data$fields === void 0 ? void 0 : _data$fields.class) || '')
          }, [((_data$fields2 = data.fields[i - 1]) === null || _data$fields2 === void 0 ? void 0 : _data$fields2.key) == 'fullNumber' ? _c('div', [_c('p', [_vm._v("Total invoices:")])]) : _vm._e(), ((_data$fields3 = data.fields[i - 1]) === null || _data$fields3 === void 0 ? void 0 : _data$fields3.key) == 'issueDate' ? _c('div', [_c('p', [_vm._v(_vm._s(_vm.items.length ? _vm.items.length : 0))])]) : _vm._e(), ((_data$fields4 = data.fields[i - 1]) === null || _data$fields4 === void 0 ? void 0 : _data$fields4.key) == 'entityAccountNumber' ? _c('div', [_c('p', [_vm._v("Total amount:")])]) : _vm._e(), ((_data$fields5 = data.fields[i - 1]) === null || _data$fields5 === void 0 ? void 0 : _data$fields5.key) == 'amount' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.amountTotals), function (_ref) {
            var key = _ref[0],
                value = _ref[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)))]);
          }), 0) : _vm._e(), ((_data$fields6 = data.fields[i - 1]) === null || _data$fields6 === void 0 ? void 0 : _data$fields6.key) == 'receivables' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.receivableTotals), function (_ref2) {
            var key = _ref2[0],
                value = _ref2[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)) + " ")]);
          }), 0) : _vm._e(), ((_data$fields7 = data.fields[i - 1]) === null || _data$fields7 === void 0 ? void 0 : _data$fields7.key) == 'payables' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.payableTotals), function (_ref3) {
            var key = _ref3[0],
                value = _ref3[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)) + " ")]);
          }), 0) : _vm._e(), ((_data$fields8 = data.fields[i - 1]) === null || _data$fields8 === void 0 ? void 0 : _data$fields8.key) == 'amountSystem' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.amountSystemTotals), function (_ref4) {
            var key = _ref4[0],
                value = _ref4[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)) + " ")]);
          }), 0) : _vm._e(), ((_data$fields9 = data.fields[i - 1]) === null || _data$fields9 === void 0 ? void 0 : _data$fields9.key) == 'receivablesSystem' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.receivableSystemTotals), function (_ref5) {
            var key = _ref5[0],
                value = _ref5[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)))]);
          }), 0) : _vm._e(), ((_data$fields10 = data.fields[i - 1]) === null || _data$fields10 === void 0 ? void 0 : _data$fields10.key) == 'payablesSystem' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.payableSystemTotals), function (_ref6) {
            var key = _ref6[0],
                value = _ref6[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)))]);
          }), 0) : _vm._e(), ((_data$fields11 = data.fields[i - 1]) === null || _data$fields11 === void 0 ? void 0 : _data$fields11.key) == 'currency' ? _c('div', _vm._l(Object.entries(_vm.amountTotals), function (_ref7) {
            var key = _ref7[0];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(key))]);
          }), 0) : _vm._e()]);
        }), 0)];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1)]), _c('booking-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showBookingModal,
      expression: "showBookingModal"
    }],
    ref: "bookingModal",
    attrs: {
      "useName": true,
      "selectedItems": _vm.bookingItems
    },
    on: {
      "cancel": _vm.closeBookingModal,
      "close": _vm.closeBookingModal
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }