























































import Vue from 'vue'
import Component from 'vue-class-component'
import StatementItemsFilters from "@/modules/statements/components/StatementItemsFilters.vue";
import StatementItemsTable from "@/modules/statements/components/StatementItemsTable.vue";
import {TableData} from "@/modules/genprox/modules/profile/store/types";
import {mapState} from "vuex";
import { TransactionItem } from '@/modules/statements/store/types';
import { BTable } from 'bootstrap-vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniModal from '@/components/layout/SygniModal.vue';

@Component({
  components: { StatementItemsFilters, StatementItemsTable, BTable, SygniRoundedButton, SygniContainerTitle, SygniLoader, SygniModal, SygniLinkButton },
  computed: mapState('statements', {
    transactionsTable: (state: any) => state.transactionsTable,
    isBusy: (state: any) => state.transactionsTableIsBusy,
    perPage: (state: any) => state.transactionsTable.perPage,
  })
})
export default class statementModule extends Vue {
  readonly supportedFileFormats: Array<string> = ['pdf', 'jpeg', 'jpg', 'png'];
  tempFile: string = "";
  transactionsTable!: TableData<TransactionItem>;
  perPage!: number;
  isBusy!: boolean;
  filtersQueryString: string = '';
  isLoading: boolean = false;
  hideAdditionalColumns: boolean = true;
  syncInfo: { new: any[], updated: any[] } = null

  statementData: TransactionItem = null

  get statementId() {
    return this.$route?.params?.id
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  getTransactionStatusClass(status: string) {
    let className = 'primary';

    if (status == 'updated') {
      className = 'success';
    }

    return className;
  }

  getTransactionStatusText(status: string) {
    return this.$options.filters.snakeCaseToTitleCase(status)
  }

  get syncInfoItems() {
    const items: any[] = []

    this.syncInfo?.new?.forEach((el: any) => {
      items.push({ ...el, status: 'new' })
    })

    this.syncInfo?.updated?.forEach((el: any) => {
      items.push({ ...el, status: 'updated' })
    })

    return items
  }

  get toggleColumnsText() {
    return this.hideAdditionalColumns ? 'Show extra columns' : 'Hide extra columns'
  }

  closeSyncInfoModal() {
    this.syncInfo = null
  }

  goBack() {
    this.$router.go(-1)
  }

  toggleAdditionalColumns() {
    this.hideAdditionalColumns = !this.hideAdditionalColumns;
  }

  async synchronizeTransactions() {
    this.isLoading = true

    try {
      const syncInfo = await this.$store.dispatch('statements/patchBankTransactions', this.$route.params?.id);
      if (syncInfo?.new?.length || syncInfo?.updated?.length) {
        this.syncInfo = syncInfo;
      } else {
        this.syncInfo = null
        this.$notify({
          type: 'success',
          title: 'Success',
          duration: 5000,
          text: 'Transactions synchronized successfully but there were no new or updated transactions'
        });
      }
      (this.$refs.transactionsTable as StatementItemsTable).getItems()
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isLoading = false
  }

  async beforeMount() {
    this.isLoading = true;
    try {
      this.statementData = await this.$store.dispatch('statements/getStatement', this.$route.params?.id)
      if (this.statementData?.status?.toLowerCase() === 'new') {
        const syncInfo = await this.$store.dispatch('statements/patchBankTransactions', this.$route.params?.id)
        if (syncInfo?.new?.length || syncInfo?.updated?.length) {
          this.syncInfo = syncInfo;
        } else {
          this.syncInfo = null
          this.$notify({
            type: 'success',
            title: 'Success',
            duration: 5000,
            text: 'Transactions synchronized but there were no new or updated transactions'
          });
        }
        (this.$refs.transactionsTable as StatementItemsTable).getItems()
      }
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    this.isLoading = false;
  }

  applyFiltersEvent(queryString: string) {
    this.filtersQueryString = queryString;
    (this.$refs.transactionsTable as StatementItemsTable).onFiltersChange(queryString);
  }
}
