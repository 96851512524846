














































































































































































































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import { Statues } from "@/shared/interfaces/Statues";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniTable from "@/components/table/SygniTable.vue";
import { Product } from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import BookingModal from '@/modules/statements/components/BookingModal.vue'
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import { create, all } from 'mathjs'
import { Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash'
import { CheckboxOption } from '@/store/types';
import { BTable } from 'bootstrap-vue';

const math = create(all);

@Component({
  components: { SygniLoader, SygniRectButton, BookingModal, SygniCheckbox, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse }
})
export default class ProductTable extends SygniTable<Product> {
  @Prop({ default: null }) statementId: string | null;
  @Prop({ default: true }) hideAdditionalColumns: boolean;

  bulkRows: Array<CheckboxOption> = [];
  selectAllRowsBoolean: boolean | null = false;
  bulkOptionsMarginTop: number = 0;
  isLoading: boolean = false;
  refresh: any = null;

  tableFields = [
    { key: 'selected', sortable: false, label: '', class: ['selected'] },
    { key: 'fullNumber', sortable: true, class: ['code'], label: 'Document No.' },
    { key: 'issueDate', label: 'Transfer Date', sortable: true, },
    { key: 'entityCode', sortable: true, class: 'source-name', label: 'Cp. Code' },
    { key: 'name', sortable: true, class: 'source-name', label: 'Cp. Name' },
    { key: 'entityAccountNumber', sortable: true, class: 'source-name', label: 'Cp. Account No.' },
    { key: 'amount', sortable: true, class: 'text-right', label: 'Amount' },
    { key: 'receivables', sortable: false, label: 'Receivables' },
    { key: 'payables', sortable: false, label: 'Payables' },
    { key: 'amountSystem', sortable: true, class: 'hide text-right', label: 'Amount PLN' },
    { key: 'receivablesSystem', sortable: false, class: 'hide', label: 'Receivables PLN' },
    { key: 'payablesSystem', sortable: false, class: 'hide', label: 'Payables PLN' },
    { key: 'currency', sortable: true, class: 'text-center', label: 'Ccy' },
    { key: 'description', sortable: true, label: 'Desc' },
    { key: 'settled', sortable: true, label: 'Settled' },
    { key: 'oppositeAccount', sortable: true, label: 'Contra account' },
    { key: 'status', sortable: true, class: 'status', label: 'Status' },
    { key: 'actions', sortable: false, label: '', class: ['actions'] },
  ]

  // ====================================
  // Synchronisation modal
  // ====================================

  isBookingModalLoading: boolean = false;
  showBookingModal: boolean = false;
  bookingItems: any[] = []

  getCurrencyLabel(currency: string | null) {
    return currency ? currency?.toUpperCase() : 'PLN'
  }

  get filtersQuery() {
    return this.$store.getters['statements/getTransactionsTableFiltersQuery'];
  }

  isDisabled(id: string) {
    const item: any = this.items?.find((item: any) => item.id === id)

    if (item?.status?.toLowerCase() === 'booked' || item?.status?.toLowerCase() === 'book') {
      return true
    }

    return false
  }

  get selectedItems(): any {
    const ids = this.rowsSelected.map((el: any) => el.label)

    return this.items?.filter((el: any) => ids.includes(el.id))
  }

  get rowsSelected() {
    const selectedRows = this.bulkRows.filter(el => el?.value);

    return selectedRows;
  }

  get selectedRowsLength() {
    const selectedRows = this.bulkRows.filter(el => el?.value);

    return selectedRows?.length;
  }

  selectRowEl(index: number) {
    if (this.$refs.statementItemsTable == undefined) return;

    this.deselectRowEl();
    const activeRow = ((this.$refs.statementItemsTable as any).$el as HTMLElement).querySelector(`tbody tr[aria-rowindex="${index + 1}"]`);
    this.$scrollTo(activeRow);
    activeRow.classList.add('active');
  }

  deselectRowEl() {
    if (this.$refs.statementItemsTable == undefined) return;

    const rows = ((this.$refs.statementItemsTable as any).$el as HTMLElement).querySelectorAll(`tbody tr`);
    rows.forEach(row => row.classList.remove('active'));
  }

  resetCheckboxes(keepExisting: boolean = false): void {
    if (!keepExisting) {
      this.bulkRows = [];
      this.items.forEach((el, index) => {
        this.$set(this.bulkRows, index, { label: el.id, value: false });
      });
    } else {
      this.items.forEach((el, index) => {
        // check if element exists
        if (!this.bulkRows[index]) {
          // add new element
          this.$set(this.bulkRows, index, { label: el.id, value: false })
        }
      })
    }
  }

  toggleTableRow(item: any, index: number) {
    // const selectedRowEl = (this.$refs.statementItemsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(${index + 1})`);
    this.bulkOptionsMarginTop = 70;
    if (this.bulkRows[index]) {
      this.bulkRows[index].value = !this.bulkRows[index]?.value;
    }

    if (item?.value) {
      (this.$refs.statementItemsTable as BTable).selectRow(index);
    } else {
      (this.$refs.statementItemsTable as BTable).unselectRow(index);
    }
  }

  rowClass(item: any) {
    return this.rowsSelected?.find((el: any) => el.label === item?.id) ? 'row-selected' : ''
  }

  get amountTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if (currency) {
        if (totals[currency]) {
          totals[currency] = item.amount ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.amount))) : math.number(math.add(math.bignumber(totals[currency]), 0));
        } else {
          totals[currency] = item.amount ? math.bignumber(math.bignumber(item.amount)) : 0;
        }
      }
    })

    return totals;
  }

  get amountSystemTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if (currency) {
        if (totals[currency]) {
          totals[currency] = item.amountSystem ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.amountSystem))) : math.number(math.add(math.bignumber(totals[currency]), 0));
        } else {
          totals[currency] = item.amountSystem ? math.bignumber(math.bignumber(item.amountSystem)) : 0;
        }
      }
    })

    return totals;
  }

  get receivableTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if (currency) {
        if (totals[currency]) {
          if (item?.direction === 1) {
            totals[currency] = item.amount ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.amount))) : math.number(math.add(math.bignumber(totals[currency]), 0));
          }
        } else {
          if (item?.direction === 1) {
            totals[currency] = item.amount ? math.bignumber(math.bignumber(item.amount)) : 0;
          } else {
            totals[currency] = 0
          }
        }
      }
    })

    return totals;
  }

  get receivableSystemTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if (currency) {
        if (totals[currency]) {
          if (item?.direction === 1) {
            totals[currency] = item.amountSystem ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.amountSystem))) : math.number(math.add(math.bignumber(totals[currency]), 0));
          }
        } else {
          if (item?.direction === 1) {
            totals[currency] = item.amountSystem ? math.bignumber(math.bignumber(item.amountSystem)) : 0;
          } else {
            totals[currency] = 0
          }
        }
      }
    })

    return totals;
  }

  get payableTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if (currency) {
        if (totals[currency]) {
          if (item?.direction === -1) {
            totals[currency] = item.amount ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.amount))) : math.number(math.add(math.bignumber(totals[currency]), 0));
          }
        } else {
          if (item?.direction === -1) {
            totals[currency] = item.amount ? math.bignumber(math.bignumber(item.amount)) : 0;
          } else {
            totals[currency] = 0
          }
        }
      }
    })

    return totals;
  }

  get payableSystemTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const currency = item?.currency || 'PLN'
      if (currency) {
        if (totals[currency]) {
          if (item?.direction === -1) {
            totals[currency] = item.amountSystem ? math.number(math.add(math.bignumber(totals[currency]), math.bignumber(item.amountSystem))) : math.number(math.add(math.bignumber(totals[currency]), 0));
          }
        } else {
          if (item?.direction === -1) {
            totals[currency] = item.amountSystem ? math.bignumber(math.bignumber(item.amountSystem)) : 0;
          } else {
            totals[currency] = 0
          }
        }
      }
    })

    return totals;
  }

  getTooltipMessage(message: string, maxSize: number = 35) {
    return message?.length <= maxSize ? '' : message
  }

  goToProfile(investor: any): void {
    if (investor.investmentClientId) {
      this.$router.push({ name: 'profile-dashboard-guest-investor', params: { id: investor.investmentClientId } })
    }
  }

  toggleAllRowsAction() {
    if (this.selectAllRowsBoolean === null) return;
    this.selectAllRowsBoolean = !this.selectAllRowsBoolean;

    if (this.selectAllRowsBoolean) {
      // const selectedRowEl = (this.$refs.statementItemsTable as BTable)?.$el?.querySelector('.table tbody tr:nth-of-type(1)');
      this.bulkOptionsMarginTop = 70;
      (this.$refs.statementItemsTable as BTable).selectAllRows();
    } else {
      (this.$refs.statementItemsTable as BTable).clearSelected();
    }

    this.bulkRows.forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  statusText(status: string) {
    if (status?.toUpperCase() === 'BOOK') {
      return 'BOOKED'
    }

    return this.$options.filters.snakeCaseToTitleCase(status)?.toUpperCase()
  }

  statusClass(status: string) {
    switch (status?.toLowerCase()) {
      case 'unmatched':
        return 'danger';
      case 'matched':
        return 'success';
      case 'booked':
      case 'book':
        return 'primary'
    }

    return 'black';
  }

  async getItems(keepExistingCheckboxes: boolean = false) {
    await this.$store.dispatch('statements/getTransactions', { filtersQuery: this.filtersQuery, id: this.statementId });
    this.resetCheckboxes(keepExistingCheckboxes)
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get hasOnlyBookableTransactions() {
    if (this.activeUserData?.role?.bankStatementRole === 'user') return false

    const invalidItems = this.selectedItems?.filter((el: any) => {
      return !el?.investmentClientId || (el?.status?.toUpperCase() === 'BOOK' || el?.status?.toUpperCase() === 'BOOKED')
    })

    return invalidItems?.length === 0
  }

  get isProcessing() {
    const items = this.items?.filter(item => (item.status as string)?.toLowerCase() == 'booking');

    return (items?.length) ? true : false;
  }

  async openBookingModal(items: string[] = []) {
    this.showBookingModal = true

    const investmentClientIds: string[] = items?.length ? items : _.uniq(this.selectedItems?.map((el: any) => el.investmentClientId));
    const filteredInvestmentClientIds: any[] = []

    for (const id of investmentClientIds) {
      const resp = await this.$store.dispatch('statements/checkInvestmentClientCounterpartyInfo', id)

      if (!(resp?.counterpartyId && resp?.code && resp?.externalId)) {
        const counterpartyObj = { investmentClientId: id, data: resp }
        filteredInvestmentClientIds.push(counterpartyObj)
      }
    }

    await (this.$refs.bookingModal as BookingModal).getCounterparties(filteredInvestmentClientIds)
  }

  async closeBookingModal() {
    this.$store.commit(this.setTableBusyMutation, true)
    this.showBookingModal = false
    await this.getItems()
  }

  async synchronise(item?: any) {
    if (item) {
      if (item?.investmentClientId && !(item?.status?.toUpperCase() === 'BOOK' || item?.status?.toUpperCase() === 'BOOKED')) {
        this.bookingItems = [item]
        this.openBookingModal([item?.investmentClientId])
      }
    } else {
      this.bookingItems = this.selectedItems
      this.openBookingModal()
    }
  }

  setQuery() {
    this.$store.commit('statements/setTransactionsTableQuery', this.localTableQuery);
  }

  onFiltersChange(filtersQuery?: string): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$store.commit('statements/setTransactionsTableFiltersQuery', filtersQuery);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch (this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('statements/setTransactionsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch (this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('statements/setTransactionsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  mounted() {
    this.onMounted();
    this.getItems();
    this.setBorderlessTds();
  }

  async goToProductSummary(product: Product) {
    this.$store.commit('investors/clearAnnexingData');
    if (product.status === Statues.pending) {
      await this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/accept-by-legal-entity/${product.id}` });
    } else {
      await this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/fundraising/product/summary/${product.id}/for-legal-entity` });
    }
  }

  @Watch('selectAllRowsBoolean') onSelectAllRowsBooleanChange(): void {
    // const selectedRowEl = (this.$refs.statementTransactionsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(1)`);
    this.bulkOptionsMarginTop = 70;

    this.bulkRows.filter(el => el?.value !== undefined).forEach(row => {
      if (!this.isDisabled(row.label)) {
        row.value = this.selectAllRowsBoolean;
      }
    });
  }

  @Watch('items') onItemsChange(): void {
    if (this.refresh) {
      clearTimeout(this.refresh);
      this.refresh = undefined;
    }

    if (this.isProcessing) {
      this.$store.commit(this.setTableBusyMutation, true);
      this.refresh = setTimeout(() => {
        this.getItems();
      }, 3000);
    }
  }
}

